import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { RichTextSerializers } from "../../utils/richTextSerializers";
import "./styles.scss";

// @ts-ignore
import BlockContent from "@sanity/block-content-to-react";

function getAlignemntCssClass(blockType: { name: string }) {
  if (blockType?.name === "Right aligned") {
    return "text-right";
  }

  if (blockType?.name === "Left aligned") {
    return "text-left";
  }

  return "text-center";
}


const TextBlockV3 = (props: { bgColor: { title: string, value: string }, _rawTextBody: any, blockType: {name: string} }) => {
  const { bgColor, _rawTextBody, blockType } = props;

  return (
    <section className={`rx-section rx-textblockv3 rx-theme-${bgColor?.title?.toLowerCase() || "teal"} ${getAlignemntCssClass(blockType)}`}>
      <Container fluid>
        <Row>
          <Col md={12}>
            <BlockContent blocks={_rawTextBody} serializers={RichTextSerializers()} />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default TextBlockV3;
